import { NextSeo } from "next-seo";

function DefaultSEO({ seo }) {
  if (!seo) return null;

  const images = [];
  if (seo.picture1) {
    images.push({
      url: `${process.env.server_url}${seo.picture1}?access_token=flex.public.token&trustkey=APP.FKJSHHHH908KJF`,
      width: 1200,
      height: 630,
      alt: "VietThien logo",
      type: "image/*",
    });
  }
  if (seo.picture2) {
    images.push({
      url: `${process.env.server_url}${seo.picture2}?access_token=flex.public.token&trustkey=APP.FKJSHHHH908KJF`,
      width: 1200,
      height: 1200,
      alt: "VietThien logo",
      type: "image/*",
    });
  }

  return (
    <NextSeo
      title={seo.title || "Trà Việt Thiên"}
      description={seo.description}
      openGraph={{
        url: "https://travietthien.vn",
        title: seo.title,
        description: seo.description,
        images,
        site_name: "Việt Thiên",
      }}
    />
  );
}

export default DefaultSEO;
