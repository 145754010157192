import slugify from "./slugifyString";

function getCategoryHref(categorySlug) {
  let href;
  if (!categorySlug) return "";

  if (categorySlug?.toLowerCase() === "viet-thien") {
    href =
      "/mua-hang/viet-thien?cat=ZF01.01|ZF01.02|ZF01.03|ZF01.04|ZF01.05|ZF02.01|ZF02.02|ZF03.01&sort=newest";
  }
  if (categorySlug?.toLowerCase() === "tra-bac") {
    href =
      "/mua-hang/tra-bac?cat=TRABAC&page=1";
  }
  if (categorySlug?.toLowerCase() === "tra-lai") {
    href = "/mua-hang/tra-lai?cat=TRALAI&page=1";
  }
  if (categorySlug?.toLowerCase() === "tra-moc-cau") {
    href = "/mua-hang/tra-moc-cau?cat=TRAMOCCAU&page=1";
  }
  if (categorySlug?.toLowerCase() === "tra-o-long") {
    href = "/mua-hang/tra-o-long?cat=TRAOLONG&page=1";
  }
  if (categorySlug?.toLowerCase() === "tra-sam-dua") {
    href = "/mua-hang/tra-sam-dua?cat=TRASAMDUA&page=1";
  }
  if (categorySlug?.toLowerCase() === "tra-tui-loc") {
    href = "/mua-hang/tra-tui-loc?cat=TRATUILOC&page=1";
  }

  return href;
}

function getProductHref(maVt, tenVt) {
  if (!maVt) return "";
  const slug = slugify(tenVt);
  return `/products/${slug}-${tenVt}`;
}

export function getHrefLinkOfBanner(item) {
  let href = "";
  if (item?.link_to_page) href = item?.link_to_page;
  if (item?.link_to_product)
    href = getProductHref(item.link_to_product, item.link_to_product_title);
  if (item?.link_to_category) href = getCategoryHref(item.link_to_category);

  return href;
}
