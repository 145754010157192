import styled from "@emotion/styled";
import { BsChevronLeft } from "@react-icons/all-files/bs/BsChevronLeft";
import { BsChevronRight } from "@react-icons/all-files/bs/BsChevronRight";
import React from "react";

const Wrapper = styled.div`
  position: absolute;
  top: ${(props) => (props.slider ? "50%" : "150px")};
  transform: translateY(-40%);
  z-index: 99;

  & svg {
    cursor: pointer;
    fill: white;
    width: 32px;
    height: 56px;
    /* border-radius: 30px; */
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  ${(props) => (props?.left ? "left: 0;" : "right: 0;")}
  ${(props) =>
    !props?.slider &&
    (props?.left
      ? "transform: translate(-100%, -40%);"
      : "transform: translate(100%, -40%);")}
`;

export function NextArrow({ onClick, slider = false }) {
  return (
    <Wrapper right slider={slider}>
      <BsChevronRight onClick={onClick} />
    </Wrapper>
  );
}
export function PrevArrow({ onClick, slider = false }) {
  return (
    <Wrapper left slider={slider}>
      <BsChevronLeft onClick={onClick} />
    </Wrapper>
  );
}
